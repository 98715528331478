 const whiskys = {
  whiskyList: [
    {
      "id":  8, "name": "Tamnavulin", "age": 3, "location": "Speyside", "state": "Opened", isScotch: true, "abv": 40,
      "appearance": "Amber gold.",
      "nose": "Rich, warm aromas of apple, toffee and honey with sweet marzipan and subtle tangy marmalade notes.",
      "palate": "Fresh, mellow with pear creamy peaches and pineapple and a hint of Demerara sugar.",
      "finish": "Rich, smooth and refreshing. A signature Speyside malt.",
      "description": "Matured in American oak barrels for a sweet, mellow taste with a rich, smooth Sherry cask finish.",
    },
    {
      "id": 11, "name": "Caol Ila 12", "age": 12, "location": "Islay", "state": "Opened", isScotch: true, "abv": 43,
      "appearance": "Pale straw.",
      "nose": "Subdued, citric fruitiness; a whiff of bath oil and dentist's mouthwash. A fresh and appetising nose, with little or no trace of smoke.",
      "palate": "Drinks well at natural strength; sweet start; pleasant, light fragrant smokiness and a lengthy finish.",
      "finish": "Sweet smokiness in the lingering, slightly sour finish.",
      "description": "Caol Ila 12 year old Single Malt Scotch Whisky is the colour of pale straw, with a delicate balance of tastes.",
    },
    {
      "id": 12, "name": "Lagavulin 16", "age": 16, "location": "Islay", "state": "Opened", isScotch: true, "abv": 43,
      "appearance": "Deep amber gold.",
      "nose": "Intensely flavoured, peat smoke with iodine and seaweed and a rich, deep sweetness.",
      "palate": "Dry peat smoke fills the palate with a gentle but strong sweetness, followed by sea and salt with touches of wood.",
      "finish": "A long, elegant peat-filled finish with lots of salt and seaweed.",
      "description": "Aged in oak casks for at least sixteen years, this much sought-after single malt has the massive peat-smoke that's typical of southern Islay - but also offering a dryness that turns it into a truly interesting dram.",
    },
    {
      "id": 15, "name": "Glenmorangie 10", "age": 10, "location": "Highland", "state": "Opened", isScotch: true, "abv": 40,
      "nose": "The scent of citrus and ripening peaches is softened by the aroma of vanilla.",
      "palate": "First, vanilla is detected on the tongue before it ripples along the palate bringing a burst of flowery fruitiness.",
      "finish": "About a minute after tasting, you are left with a clean and salving aftertaste with hints of orange and peach.",
      "description": "The original expression of our elegant, floral spirit and the real backbone of the Glenmorangie range. A ten-year-old single malt, Glenmorangie Original is produced by marrying the delicate spirit that emerges from Scotland's tallest stills, with first and second fill American white oak casks.<br />It is here, maturing for ten long years in a range of ex-bourbon casks such as our famous slow-grown and air-dried 'designer casks' from Missouri, that our raw spirit develops a perfect balance between sweetness and complexity. Resulting in a mature spirit that is soft, mellow and creamy. Perfect for enjoying at any time.",
    },
    {
      "id": 22, "name": "Westland's Sherry Wood", "age": 3, "location": "USA", "state": "Closed", isScotch: false, "abv": 46,
      "nose": "Honey-dipped oatmeal raisin cookies, maple syrup, banana pancakes.",
      "palate": "Kiwi, maple syrup, raisins, sweet cookies, pastries, stewed yellow fruits.",
      "description": "While the practice of maturing whiskey in sherry casks isn’t as long-standing as peated whiskies, it has become a standard of old world sensibilities. We celebrate this custom by filling hogsheads and butts sourced from the same region the Scottish find their casks. But what we do with them differs. Again, our five-malt spirit plays a significant role, allowing us to balance the sherry influence with our local malted barley flavor. We have our sherry casks shipped whole, which is unusual in the business of whiskey. This provides a sherry character that is still incredibly vibrant in this whiskey, but we always ensure the barley notes have an equal voice, something rarely done in Scotland.",
    },
    {
      "id": 24, "name": "GlenDronach 12 Original", "age": 12, "location": "Highland", "state": "Opened", isScotch: true, "abv": 43,
      "appearance": "Deep amber-red gold.",
      "nose": "Sweet, creamy vanilla, with hints of ginger. Spiced mulled wine and pear.",
      "palate": "Rich, creamy, silky-smooth. Warm, rich oak and sherry sweetness, full mouth feel, raisins and soft fruits. Spicy with medium length and a dry finish.",
      "finish": "Long, full and firm, slightly nutty.",
      "description": "This superb richly sherried single malt is matured for at least 12 years in a combination of the finest Spanish Pedro Ximenez and Oloroso sherry casks. Non-chill filtered, of natural colour and bottled at 43%, the GlenDronach 12 year old Original is a sweet, creamy dram.",
    },
    {
      "id": 26, "name": "Suntory Yamazaki 12", "age": 12, "location": "Japan", "state": "Closed", isScotch: false, "abv": 43,
      "appearance": "Pure gold",
      "nose": "Peach, pineapple, grapefruit, clove, candied orange, vanilla, Mizunara (Japanese oak)",
      "palate": "Coconut, cranberry, butter",
      "finish": "Sweet ginger, cinnamon, long finish",
      "description": "Succulent with soft fruit.",
    },
    {
      "id": 32, "name": "Glenmorangie 18", "age": 18, "location": "Highland", "state": "Closed", isScotch: true, "abv": 43,
      "nose": "Rich, rounded and sweet with dried fruits and a complex floral fragrance.",
      "palate": "The taste is balanced between honey, malt and flowery scents. Dates and figs emerge in the background with a hint of wood smoke.",
      "finish": "The finish is long and enticing with the sweetness of dried fruit and subtle dryness of Oloroso nuttiness.",
      "description": "The 18 Years Old is a single malt Scotch whisky of serious distinction. Once it has spent fifteen years maturing in American white oak casks approximately 30% is transferred into Spanish Oloroso casks to spend a further three years maturing. Then, when both elements have reached 18 years, we blend them back together to create a whisky with a rich bouquet and full, rounded flavour. For a whisky of this age this expression has maintained, thanks to our delicate floral spirit and fine Oloroso casks, an incredible degree of vibrancy where the raisiny sweetness of the sherry has been toned down by the nuttiness of the American oak.",
    },
    {
      "id": 33, "name": "GlenDronach 18 Allardice", "age": 18, "location": "Highland", "state": "Opened", isScotch: true, "abv": 46,
      "appearance": "Bright deep gold with a tawny centre.",
      "nose": "Sweet aromatics of fudge and Muscovado sugar. Fruit compote and glacier morello cherries provide added complexity.",
      "palate": "Rich dark and seductive. Remarkable flavours of stewed fruits and all-spice marry together with classic aged Oloroso sherry and toasted walnut bread combined with chocolate orange.",
      "finish": "Tremendously complex and long.",
      "description": "The GlenDronach 18 years old has been named after the renowned founder of the distillery, James Allardice. This exceptional sherried single malt is non chill filtered and of natural colour. Matured in the finest Spanish Oloroso sherry casks and bottled at 46%, this sublime richly sherried malt is truly unforgettable.",
    },
    {
      "id": 36, "name": "Port Askaig 8", "age": 8, "location": "Islay", "state": "Opened", isScotch: true, "abv": 45.8,
      "description": "The perfect introduction to the Isle of Islay, Port Askaig 8 Year Old has a soft, sweet peaty character balanced to perfection. Ageing in refill American oak allows the character of the spirit to shine through, making for a gentle, smoky, moreish dram.",
    },
    {
      "id": 37, "name": "GlenDronach 15 Revival", "age": 15, "location": "Highland", "state": "Opened", isScotch: true, "abv": 46,
      "appearance": "Antique Bronze.",
      "nose": "An intoxicating burst of maraschino cherry, ripe bramble and dark chocolate mint with hints of orange bitters and walnut liqueur.",
      "palate": "Honey-glazed apricot and ripe fig with a crescendo of black cherry, angelica and muscovado. Long and enveloping finish with dark manuka honey, herbal bitters and dark chocolate.",
      "description": "After ceasing production in 2015, The GlenDronach Revival Aged 15 years now returns to the core range  of the distillery’s line-up. The expression embodies The GlenDronach’s signature style of Spanish Sherry  Cask maturation in fine Pedro Ximénez and Oloroso sherry casks from Andalucía, quietly growing in stature for 15 years in the darkness of our dunnage warehouses.<br />In flavour, the expression offers sumptuous notes of dark fruits, rich chocolate and manuka honey with  an enveloping, memorable finish worthy of this monumental malt’s history. This luxuriously sherried Highland Single Malt Scotch Whisky is bottled at 46% ABV, non chill filtered and natural in colour.",
    },
    {
      "id": 38, "name": "Redbreast 12", "age": 12, "location": "Ireland", "state": "Opened", isScotch: false, "abv": 40,
      "nose": "A complex spicy and fruity aroma with toasted wood notes evident.",
      "palate": "Full flavoured and complex; silky smooth with a harmonious balance of spicy, fruity, sherry and toasted notes.",
      "finish": "Satisfyingly long, the complex flavours linger on the palate.",
      "description": "Made from a mash of malted and unmalted barley and then triple distilled in copper pot stills, Redbreast 12 boasts the flavour complexity and distinctive qualities of Pot Still whiskey. Matured in a combination of Bourbon seasoned American Oak barrels and Oloroso Sherry seasoned Spanish oak butts, the distinctive Redbreast sherry style is a joy to behold in each and every bottle.",
    },
    {
      "id": 39, "name": "Suntory Yamazaki 12", "age": 12, "location": "Japan", "state": "Closed", isScotch: false, "abv": 43,
      "appearance": "Pure gold",
      "nose": "Peach, pineapple, grapefruit, clove, candied orange, vanilla, Mizunara (Japanese oak)",
      "palate": "Coconut, cranberry, butter",
      "finish": "Sweet ginger, cinnamon, long finish",
      "description": "Succulent with soft fruit.",
    },
    {
      "id": 43, "name": "BenRiach 12", "age": 12, "location": "Speyside", "state": "Opened", isScotch: true, "abv": 46,
      "appearance": "Deep golden-bronze.",
      "nose": "Sherry cask maturation at its most fruity and luscious. Baked fruit salad with fig, pear, apricot and glacé cherry in mandarin syrup. Honey glazed flan with crème-caramel and toasted vanilla pod add to the multi-layered sweetness.",
      "palate": "A rich fruit cocktail of honeyed fig, baked pineapple and sultana fused with chocolate fondant and muscovado sugar, lifted by mandarin cream, ground nutmeg and vanilla. On the finish savour nectarine, candied grape and star anise, lingering into the dessert-like sherried finish.",
      "description": "At BenRiach Distillery we have developed a reputation for innovative maturation, using a diverse mix of cask types. This luscious 12 year old Single Malt Scotch Whisky is unconventionally made using three facets of sherry cask maturation: full sherry cask maturation, combined with Pedro Ximénez and Oloroso sherry cask finishing, for unexpected layers of fruit sweetness and toasted oak spice. Each facet of sherry cask maturation is expertly combined by our Master Blender to work in harmony, creating a luscious Single Malt Scotch Whisky with a multi- layered cocktail of fruit notes, further enriching BenRiach’s signature sweet, fruit-laden style.<br />Bottled at 46%, non-chill filtered and of natural colour.",
    },
    {
      "id": 44, "name": "Glen Scotia 18", "age": 18, "location": "Campbeltown", "state": "Opened", isScotch: true, "abv": 46,
      "nose": "Crisp saltiness, perfumed floral notes and thick sweet toffee.",
      "palate": "Rich deep vanilla fruit flavours, apricot and pineapple, plump sultana.",
      "finish": "Long and dry with gentle warming spice.",
      "description": "An exceptional single malt whisky matured from specially selected casks that deliver the true character of a 18 year old Glen Scotia malt whisky.",
    },
    {
      "id": 45, "name": "Slane Irish Whiskey", "age": 3, "location": "Ireland", "state": "Opened", isScotch: false, "abv": 40,
      "appearance": "A warm, golden topaz with hues of rich toffee.",
      "nose": "Complex fruit with drizzles of caramel, butterscotch and vanilla; brown spice and toasted oak.",
      "palate": "Spicy at first but quickly sweetened with rich caramel, vanilla and butterscotch atop a deep layer of dried fruit.",
      "finish": "Lingering hints of dry fruit and caramelised wood sugar.",
      "description": "Slane Irish Whiskey is a smooth spirit of natural character with untamed notes of oak and spice from a signature triple casked blend.<br />From the family Conyngham of Slane Castle, the barrel-raising art of Brown-Forman, and the proud people of Slane Village, this is a whiskey for those living the stories that will be told for generations to come.",
    },
    {
      "id": 47, "name": "Springbank 15", "age": 15, "location": "Campbeltown", "state": "Opened", isScotch: true, "abv": 46,
      "nose": "Demerara sugar, dark chocolate, Christmas cake, almonds, toffee, oak.",
      "palate": "Creamy, raisins, dark chocolate, figs, marzipan, brazil nuts and vanilla.",
      "finish": "Oak and sherry notes sustain and mingle with hints of leather.",
      "description": "Like a storm gathering off the Kintyre coast, our 15-year-old Springbank is dark and ominous, yet delicious. Best enjoyed after dinner or with your favourite cigar, this is a true classic.",
    },
    {
      "id": 50, "name": "GlenDronach 12 Original", "age": 12, "location": "Highland", "state": "Closed", isScotch: true, "abv": 43,
      "appearance": "Deep amber-red gold.",
      "nose": "Sweet, creamy vanilla, with hints of ginger. Spiced mulled wine and pear.",
      "palate": "Rich, creamy, silky-smooth. Warm, rich oak and sherry sweetness, full mouth feel, raisins and soft fruits. Spicy with medium length and a dry finish.",
      "finish": "Long, full and firm, slightly nutty.",
      "description": "This superb richly sherried single malt is matured for at least 12 years in a combination of the finest Spanish Pedro Ximenez and Oloroso sherry casks. Non-chill filtered, of natural colour and bottled at 43%, the GlenDronach 12 year old Original is a sweet, creamy dram.",
    },
    {
      "id": 52, "name": "GlenDronach 18 Allardice", "age": 18, "location": "Highland", "state": "Closed", isScotch: true, "abv": 46,
      "appearance": "Bright deep gold with a tawny centre.",
      "nose": "Sweet aromatics of fudge and Muscovado sugar. Fruit compote and glacier morello cherries provide added complexity.",
      "palate": "Rich dark and seductive. Remarkable flavours of stewed fruits and all-spice marry together with classic aged Oloroso sherry and toasted walnut bread combined with chocolate orange.",
      "finish": "Tremendously complex and long.",
      "description": "The GlenDronach 18 years old has been named after the renowned founder of the distillery, James Allardice. This exceptional sherried single malt is non chill filtered and of natural colour. Matured in the finest Spanish Oloroso sherry casks and bottled at 46%, this sublime richly sherried malt is truly unforgettable.",
    },
    {
      "id": 52, "name": "GlenDronach 15 Revival", "age": 15, "location": "Highland", "state": "Closed", isScotch: true, "abv": 46,
      "appearance": "Antique Bronze.",
      "nose": "An intoxicating burst of maraschino cherry, ripe bramble and dark chocolate mint with hints of orange bitters and walnut liqueur.",
      "palate": "Honey-glazed apricot and ripe fig with a crescendo of black cherry, angelica and muscovado. Long and enveloping finish with dark manuka honey, herbal bitters and dark chocolate.",
      "description": "After ceasing production in 2015, The GlenDronach Revival Aged 15 years now returns to the core range  of the distillery’s line-up. The expression embodies The GlenDronach’s signature style of Spanish Sherry  Cask maturation in fine Pedro Ximénez and Oloroso sherry casks from Andalucía, quietly growing in stature for 15 years in the darkness of our dunnage warehouses.<br />In flavour, the expression offers sumptuous notes of dark fruits, rich chocolate and manuka honey with  an enveloping, memorable finish worthy of this monumental malt’s history. This luxuriously sherried Highland Single Malt Scotch Whisky is bottled at 46% ABV, non chill filtered and natural in colour.",
    },
    {
      "id": 53, "name": "GlenDronach 21 Parliament", "age": 21, "location": "Highland", "state": "Closed", isScotch: true, "abv": 48,
      "appearance": "Deep amber with a cherry wood sheen.",
      "nose": "A delicate mix of ripe autumnal fruits - notably blackberries and red plums. Rich Oloroso sherry and candied orange segments. Spiced oatmeal biscuits and toasted oak fragrances bring excellent weight and balance.",
      "palate": "Resolute flavours of fine Oloroso sherry and bitter chocolate sauce, which has been spread liberally over homemade plum pudding. This is all infused with fabulous spicy notes - cinnamon, allspice and nutmeg. Full bodied with smooth tannins.",
      "finish": "Long and lingering.",
      "description": "Matured in a combination of the finest Oloroso and Pedro Ximenez sherry casks for a minimum of 21 years, the ‘Parliament’ continues the great GlenDronach tradition of offering fruit-laden intensity in its single malts. This rich expression has been named ‘Parliament’ after the colony, or ‘parliament’, of rooks that have been nesting in the trees that overlook the GlenDronach distillery for almost 200 years. Bottled at 48%, the ‘Parliament’ is non chill filtered and of natural colour.",
    },
    {
      "id": 55, "name": "Springbank 15", "age": 15, "location": "Campbeltown", "state": "Closed", isScotch: true, "abv": 46,
      "nose": "Demerara sugar, dark chocolate, Christmas cake, almonds, toffee, oak.",
      "palate": "Creamy, raisins, dark chocolate, figs, marzipan, brazil nuts and vanilla.",
      "finish": "Oak and sherry notes sustain and mingle with hints of leather.",
      "description": "Like a storm gathering off the Kintyre coast, our 15-year-old Springbank is dark and ominous, yet delicious. Best enjoyed after dinner or with your favourite cigar, this is a true classic.",
    },
    {
      "id": 56, "name": "GlenDronach 12 Original", "age": 12, "location": "Highland", "state": "Closed", isScotch: true, "abv": 43,
      "appearance": "Deep amber-red gold.",
      "nose": "Sweet, creamy vanilla, with hints of ginger. Spiced mulled wine and pear.",
      "palate": "Rich, creamy, silky-smooth. Warm, rich oak and sherry sweetness, full mouth feel, raisins and soft fruits. Spicy with medium length and a dry finish.",
      "finish": "Long, full and firm, slightly nutty.",
      "description": "This superb richly sherried single malt is matured for at least 12 years in a combination of the finest Spanish Pedro Ximenez and Oloroso sherry casks. Non-chill filtered, of natural colour and bottled at 43%, the GlenDronach 12 year old Original is a sweet, creamy dram.",
    },
    {
      "id": 57, "name": "GlenDronach 18 Allardice", "age": 18, "location": "Highland", "state": "Closed", isScotch: true, "abv": 46,
      "appearance": "Bright deep gold with a tawny centre.",
      "nose": "Sweet aromatics of fudge and Muscovado sugar. Fruit compote and glacier morello cherries provide added complexity.",
      "palate": "Rich dark and seductive. Remarkable flavours of stewed fruits and all-spice marry together with classic aged Oloroso sherry and toasted walnut bread combined with chocolate orange.",
      "finish": "Tremendously complex and long.",
      "description": "The GlenDronach 18 years old has been named after the renowned founder of the distillery, James Allardice. This exceptional sherried single malt is non chill filtered and of natural colour. Matured in the finest Spanish Oloroso sherry casks and bottled at 46%, this sublime richly sherried malt is truly unforgettable.",
    },
    {
      "id": 58, "name": "GlenDronach 15 Revival", "age": 15, "location": "Highland", "state": "Closed", isScotch: true, "abv": 46,
      "appearance": "Antique Bronze.",
      "nose": "An intoxicating burst of maraschino cherry, ripe bramble and dark chocolate mint with hints of orange bitters and walnut liqueur.",
      "palate": "Honey-glazed apricot and ripe fig with a crescendo of black cherry, angelica and muscovado. Long and enveloping finish with dark manuka honey, herbal bitters and dark chocolate.",
      "description": "After ceasing production in 2015, The GlenDronach Revival Aged 15 years now returns to the core range  of the distillery’s line-up. The expression embodies The GlenDronach’s signature style of Spanish Sherry  Cask maturation in fine Pedro Ximénez and Oloroso sherry casks from Andalucía, quietly growing in stature for 15 years in the darkness of our dunnage warehouses.<br />In flavour, the expression offers sumptuous notes of dark fruits, rich chocolate and manuka honey with  an enveloping, memorable finish worthy of this monumental malt’s history. This luxuriously sherried Highland Single Malt Scotch Whisky is bottled at 46% ABV, non chill filtered and natural in colour.",
    },
    {
      "id": 59, "name": "GlenDronach 21 Parliament", "age": 21, "location": "Highland", "state": "Closed", isScotch: true, "abv": 48,
      "appearance": "Deep amber with a cherry wood sheen.",
      "nose": "A delicate mix of ripe autumnal fruits - notably blackberries and red plums. Rich Oloroso sherry and candied orange segments. Spiced oatmeal biscuits and toasted oak fragrances bring excellent weight and balance.",
      "palate": "Resolute flavours of fine Oloroso sherry and bitter chocolate sauce, which has been spread liberally over homemade plum pudding. This is all infused with fabulous spicy notes - cinnamon, allspice and nutmeg. Full bodied with smooth tannins.",
      "finish": "Long and lingering.",
      "description": "Matured in a combination of the finest Oloroso and Pedro Ximenez sherry casks for a minimum of 21 years, the ‘Parliament’ continues the great GlenDronach tradition of offering fruit-laden intensity in its single malts. This rich expression has been named ‘Parliament’ after the colony, or ‘parliament’, of rooks that have been nesting in the trees that overlook the GlenDronach distillery for almost 200 years. Bottled at 48%, the ‘Parliament’ is non chill filtered and of natural colour.",
    },
    {
      "id": 60, "name": "Glen Moray", "age": 3, "location": "Speyside", "state": "Opened", isScotch: true, "abv": 40,
      "appearance": "Light Gold.",
      "nose": "Citrus fruits, butterscotch and warm freshly baked shortbread.",
      "palate": "Malty toffee sweetness with blackcurrant and a fragrant lemongrass citrus edge.",
      "finish": "Comforting and warm. Caramelised citrus fruits drift away to expose subtle spice.",
      "description": "Light, smooth and fruity, our Classic is the perfect introduction to the world of single malt Scotch whisky. Encapsulating the flavours for which Glen Moray and indeed Speyside itself is loved for around the world. Approachable and easy drinking, matured entirely in American oak casks, our Classic opens the door to discovering the rest of Glen Moray's whiskies.",
    },
    {
      "id": 61, "name": "Laphroaig Select", "age": 3, "location": "Islay", "state": "Opened", isScotch: true, "abv": 40,
      "appearance": "A sparkling gold with layers of colour.",
      "nose": "Peat first, then ripe red fruits with a hint of dryness.",
      "palate": "Sweet up front then classic dry, peaty, ashy flavours followed by a rich finish.",
      "finish": "Long lingering and florally with marzipan and limes at the end.",
      "description": "This Laphroaig is created from carefully selected casks of each of our key styles. Mostly, these are historic styles, with one notable addition: the heart of the spirit is drawn from a final maturation in American oak casks – rarely used for scotch whisky.<br />With spirit selected by our master distiller from Quarter Cask, our PX Cask and Triple Wood (Oloroso sherry) and a final addition of 10 Year Old, it represents a subtle blending of peat, oak and sweetness.<br />Sparkling gold with layers of colour, the result is an exquisite Laphroaig with our trademark ‘peat reek’ matched by an additional layer of complexity and depth brought about by the fusion of the maturation styles and different oaks.",
    },
    {
      "id": 62, "name": "Arran Sherry Cask", "age": 3, "location": "Highland", "state": "Opened", isScotch: true, "abv": 55.8,
      "nose": "Toffee, sweet spice and fruit",
      "palate": "Ripe fig and cherries",
      "finish": "Dark chocolate, Mandarins, Ripe figs.",
      "description": "Matured in some of the finest casks available to the whisky industry, this cask strength Sherry matured Single Malt is both luxurious and elegant with layers of rich sweet spice and oak. Our Master Distiller has worked with producers in some of the most prestigious Sherry Bodegas in Jerez, Spain to select only the best quality casks to be filled with our precious spirit.<br />This bottling is a return to the style of some of the earliest Arran Single Malt, which was mostly matured in Sherry Casks. During a tasting, this expression is the perfect partner to our Quarter Cask 'The Bothy' as it showcases the skill and expertise of our Master Distiller in his work with different wood types and supreme cask selection.",
    },
  ]
};

export default whiskys;
